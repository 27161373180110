import './App.css';
import Article from "./article";
import Register from "./register";
import {Helmet} from "react-helmet-async";
import SearchBox from "./SearchBox";
import useModal from "./ModalProvider";
import ModalContent from "./ModalContent";

function App() {
  return (
    <div className={"bg-[#F6F8F8]"}>
      <Helmet>
        <title>ウェビナル</title>
      </Helmet>
      <div className="sticky top-0 md:h-fit border-b shadow bg-white z-10 ">
        <div className={"px-2 py-2 md:py-4 md:px-4 container mx-auto flex items-center gap-4 text-nowrap"}>
          <img className={"block w-16 lg:w-fit mx-auto"} src={"/logo.svg"} alt={"site image1"}/>
          <div className={"hidden  flex-1 font-bold lg:flex gap-8"}>
            <a href="#">カテゴリから探す</a>
            <a href="#">人気ランキング</a>
            <a href="#">新着</a>
          </div>
          <div className={"hidden  lg:flex flex-row gap-4"}>
            <SearchBox/>
            <a href="#" className={"rounded-full block px-4 py-2 border border-black"}>ログイン</a>
            <a href="#" className={"rounded-full block px-4 py-2 border border-black"}>新規登録</a>
          </div>
        </div>
      </div>
      <div className={"bg-red-400 text-center text-xs top-0 py-1 text-white"}>
        デモページです。コンテンツの内容はダミーです。機能も実装中や制限中になります。
      </div>

      <div className="px-4 container xl:max-w-screen-xl mx-auto my-4 min-h-screen">
        <div className={"mt-6 md:mt-12"}>
          <div className={"flex gap-4"}>
            <div className={"w-2 bg-webinal-green rounded"}></div>
            <h1
              className={"text-xl md:text-4xl font-bold text-webinal-black "}>【SNS広告徹底比較】Webマーケ企業の運用生データを大公開</h1>
          </div>
        </div>
        <div className={"flex flex-wrap gap-4 mt-4"}>
          <a href="#"
             className={"rounded-full border border-webinal-green border-2 text-webinal-green font-bold inline-block px-4 py-1 text-xs"}>webマーケティング</a>
          <a href="#"
             className={"rounded-full border border-webinal-green border-2 text-webinal-green font-bold inline-block px-4 py-1 text-xs"}>SNS広告</a>
        </div>
        <div className={"flex flex-col-reverse md:flex-row gap-4 mt-4 md:mt-8"}>
          <div className={"w-full lg:w-2/3"}>
            <div className={"rounded bg-white p-4 flex flex-col gap-2"}>
              <p className={"font-bold"}>スピーカー</p>
              <div className={"flex flex-col gap-4"}>
                <div className={"flex gap-4"}>
                  <img src={"/user1.png"} className={"rounded-full w-12 h-12"} alt={"post image2"}/>
                  <div>
                    <p className={"font-bold"}>山田太郎</p>
                    <p>株式会社Webinal</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={"mt-8 rounded p-4 bg-white"} id={"webinar-article"}>
              <Article/>
            </div>
          </div>
          <div className={"flex-auto hidden lg:block"}>
            <div className={"rounded bg-white p-8 sticky top-24"}>
              <Register/>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24 h-20 bg-white">
        <div className={"py-4"}>
          <img className={"mx-auto"} src={"/logo.svg"} alt={"image4"}/>
        </div>
      </div>
      <div className="lg:hidden block sticky bottom-0 h-fit border-b shadow bg-white z-10 ">
        <div className={"py-4 px-4 container mx-auto flex items-center gap-4 text-nowrap"}>
          <div className={"flex flex-row gap-4 justify-center mx-auto items-center"}>
            <SPButton />
          </div>
        </div>
      </div>
    </div>
  );
}
const SPButton = ({ children }) => {
  const { openModal, closeModal } = useModal()
  return (

    <button
      onClick={() => openModal(<ModalContent onClose={closeModal}/>)}
      className="text-wrap text-xs bg-webinal-green text-white font-bold py-2 px-4 rounded fucus:outline-none focus:shadow-outline"
    >
      モザイクの部分や気になる部分を担当者から聞くことができます
    </button>
  )
}
export default App;
