"use client"

import { useState } from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

export default function SearchBox() {
  const [isExpanded, setIsExpanded] = useState(true)

  const toggleSearch = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="relative flex items-center">
      <button
        className=""
        onClick={toggleSearch}
      >
        <FontAwesomeIcon icon={faSearch} className="h-5 w-5" />
        <span className="sr-only">Toggle search</span>
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isExpanded ? "w-64 opacity-100" : "w-0 opacity-0"
        }`}
      >
        <input
          type="search"
          placeholder="検索..."
          className="w-full pl-10"
        />
      </div>
    </div>
  )
}